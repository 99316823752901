import { MintConfigOptions } from '@bryllyant/mint-ngx';
import { _default } from './default';

export const environment: MintConfigOptions = {
  env: 'production',
  configs: [
    {
      _default: _default,
    },
    {
      production: {
        websiteBaseUrl: 'https://fynvana.com/',
        adminBaseUrl: 'https://admin.fynvana.com/',
        taxpayerBaseUrl: 'https://taxpayer.fynvana.com/',
        docsBaseUrl: 'https://docs.fynvana.com/',
        clientBaseUrl: 'https://client.fynvana.com/',

        api: {
          baseUrl: 'https://api.fynvana.com/',
        },

        logger: {
          level: 'info',
        },

        services: {
          docusign: {
            integrationKey: '3779ec0f-745c-459a-88d0-62ed414c2ecb',
          },
        },
      },
    },
  ],
};
